import { type ColumnsType } from '../../../../components/table/types/columns.type';
import React from 'react';
import { Link } from 'react-router-dom';
import { type GetRequestProjectType } from '../../../../api/repository/types/request-project.type';
import ActionCell from '../cell/ActionCell';
import RequestProjectList from '../request-project.list';
import { getProjectName } from '../../../../services/string/ProjectUtil';
import StringUtil from '../../../../services/string/StringUtil';

export const columns: Array<ColumnsType<GetRequestProjectType>> = [
  {
    dataField: 'state',
    text: 'Etat'
  },
  {
    dataField: 'name',
    text: 'Nom'
  },
  {
    dataField: 'privateVisibility',
    text: 'Visibilité',
    formatter: (cell) => {
      return cell == true ? 'Privée' : 'Publique'; // ou autre condition si nécessaire
    }
  },
  {
    dataField: 'type - destination',
    text: 'Type',
    formatter: (_, requestProject) => {
      return (
        <>
          {requestProject.type} - {requestProject.destination}
        </>
      );
    }
  },
  {
    dataField: 'product',
    text: 'Produit',
    preRender: {
      type: 'html'
    }
  },
  {
    dataField: 'nbPerson',
    text: 'Réseau'
  },
  {
    dataField: 'creator',
    text: 'Créé par',
    formatter: (cell, requestProject) => {
      return <Link to={`/users/${requestProject.creator.id}/edit`}>{requestProject.creator.email}</Link>;
    }
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'datetime'
    }
  },
  {
    dataField: 'project.name',
    text: 'Projet relié',
    formatter: (cell, requestProject) => (requestProject.project ? <Link to={`/projects/${requestProject.project.id}/edit/informations`}>{StringUtil.removeHTMLTags(getProjectName(requestProject))}</Link> : null)
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (_cell, requestProject) => {
      return <ActionCell requestProject={requestProject} />;
    },
    classes: 'actions'
  }
];
